export enum ModernTheme {
  Red,
  Blue,
  DarkBlue,
  Purple,
  Green,
  Orange,
  Black,
  Gray,
  Yellow,
  Transparent,
}

export const JGUColors = {
  White: "#FFFFFF",
  Red: "#C1002A",
  Orange: "#FF5500",
  Blue: "#046380",
  DarkBlue: "#024358",
  Green: "#00A023",
  Gray: "#636363",
  Yellow: "#F1C645",
  Purple: "#754363",
  Black: "#2D3A3F",
};
