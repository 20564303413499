import { useLitteraMethods } from "@assembless/react-littera";
import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Location,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { OffcanvasComponent } from "@/components/ui/Offcanvas";
import {
  ToastManagerComponent,
  ToastManagerInstance,
} from "@/components/ui/ToastManager";

import { T, usePreferredLanguage } from "@/components/ui/T";
import { ModernTheme } from "@/components/ui/Theme";
import { RequireAuth } from "@/utility/Authentication";
import Cookies from "js-cookie";

import { LoadingBackgroundEffect } from "@/components/ui/LoadingScreen";
import NavigationBar from "@/components/ui/NavigationBar";

//////////////////////////////////////////////
// IMPORT COMPONENTS /////////////////////////////////////////
//////////////////////////////////////////////

// prettier-ignore
const Footer = React.lazy(() => import("@/components/ui/Footer"));
// prettier-ignore
const ErrorPage = React.lazy(() => import("@/components/pages/ErrorPage"));
// prettier-ignore
const HomePage = React.lazy(() => import("@/components/pages/HomePage"));
// prettier-ignore
const WavesJLPage = React.lazy(() => import("@/components/pages/WavesJLPage"));
// prettier-ignore
const AboutPage = React.lazy(() => import("@/components/pages/AboutPage"));
// prettier-ignore
const ExhibitionPage = React.lazy(() => import("@/components/pages/ExhibitionPage"));
// prettier-ignore
const MonitorPage = React.lazy(() => import("@/components/pages/MonitorPage"));
// prettier-ignore
const SpAIseplanPage = React.lazy(() => import("@/components/pages/SpaiseplanPage"));
// prettier-ignore
const MonitorMensaPage = React.lazy(() => import("@/components/pages/MonitorSpAIseplanPage"));
// prettier-ignore
const AdminPage = React.lazy(() => import("@/components/pages/AdminPage"));
// prettier-ignore
const ModerationPage = React.lazy(() => import("@/components/pages/ModerationPage"));
// prettier-ignore
const LegacyCampusPage = React.lazy(() => import("@/components/pages/LegacyCampusPage"));
// prettier-ignore
const ResearchGroupSlidePreviewPage = React.lazy(() => import("@/components/pages/ResearchGroupSlidePreviewPage"));

//////////////////////////////////////////////
// UTILITY FUNCTIONS /////////////////////////////////////////
//////////////////////////////////////////////

const waitForToastManager = (message: string) => {
  if (!ToastManagerInstance) {
    setTimeout(() => {
      waitForToastManager(message);
    }, 1000);
  } else {
    ToastManagerInstance.showToast({
      icon: <i className="bi bi-exclamation-triangle-fill"></i>,
      header: <T de="Hinweis" en="Notice" />,
      autoHide: 6000,
      theme: ModernTheme.Yellow,
      children: <div className="text-neutral-800">{message}</div>,
    });
  }
};

const handleAnalytics = async () => {
  let country = Cookies.get("country");
  if (country === undefined) {
    // get country from ip
    const req = await fetch("https://ipapi.co/json/");
    const res = await req.json();
    country = res.country_name ?? "Unknown";
    Cookies.set("country", country!);
  }
  await fetch(
    `/api/analytics?url=${encodeURIComponent(location.pathname + location.search)}&country=${country}`,
  );
};

const handleLocation = (preferredLanguage: string, location: Location<any>) => {
  if (location.pathname.match(/\/de\/?$/)) {
    Cookies.set("language", "de");
    return "de_DE";
  } else if (location.pathname.match(/\/en\/?$/)) {
    Cookies.set("language", "en");
    return "en_US";
  } else {
    if (preferredLanguage == "de") {
      Cookies.set("language", "de");
      return "de_DE";
    } else {
      Cookies.set("language", "en");
      return "en_US";
    }
  }
};

const makeLocalizedRoute = (
  props: React.ComponentProps<typeof Route> & {
    preferredLanguage?: string;
  },
) => {
  const routeProps = { ...props };
  delete routeProps.preferredLanguage;

  return [
    <Route
      key="redirect"
      {...routeProps}
      path={props.path}
      element={
        <Navigate
          replace
          to={`/${props.preferredLanguage ?? "en"}${props.path}`}
        />
      }
    />,
    <Route
      key="de"
      {...routeProps}
      path={"/de" + props.path}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          {props.element}
        </React.Suspense>
      }
    />,
    <Route
      key="en"
      {...routeProps}
      path={"/en" + props.path}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          {props.element}
        </React.Suspense>
      }
    />,
  ];
};

//////////////////////////////////////////////
// UTILITY COMPONENTS ////////////////////////////////////////
//////////////////////////////////////////////

const LoadingScreen = (props: { pad?: boolean }) => {
  return (
    <div
      className={
        "fullbody flex h-[100dvh] w-full bg-white " + (props.pad ? "pt-20" : "")
      }
    >
      <div className="relative flex h-full w-full flex-col items-center justify-center gap-2 bg-white bg-linear-to-tr">
        <LoadingBackgroundEffect />

        {/* <div className="absolute inset-0 flex items-center justify-center">
          <div className="opacity-90 grayscale">
            <div className="animate-pulse">
              <AppLogo noText />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const ScrollToTopOnNavigate = (props: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
};

//////////////////////////////////////////////
// LAYOUT COMPONENTS /////////////////////////////////////////
//////////////////////////////////////////////

const DocumentLayout = () => {
  return (
    <div className="flex flex-col">
      <React.Suspense fallback={<></>}>
        <NavigationBar />
      </React.Suspense>
      <main className="bg-jgu-red-10 grow">
        <ScrollToTopOnNavigate>
          <Outlet /> {/* Render the child route */}
        </ScrollToTopOnNavigate>
      </main>
      <React.Suspense fallback={<></>}>
        <Footer />
      </React.Suspense>
    </div>
  );
};

const FullscreenLayout = () => {
  return <Outlet />;
};

//////////////////////////////////////////////
// MAIN COMPONENT /////////////////////////////////////////
//////////////////////////////////////////////

const App = () => {
  const location = useLocation();
  const methods = useLitteraMethods();
  const preferredLanguage = usePreferredLanguage();

  useEffect(() => {
    methods.setLocale(handleLocation(preferredLanguage, location));
    handleAnalytics();

    const queryParameters = new URLSearchParams(window.location.search);
    const message = queryParameters.get("message");
    if (message) {
      waitForToastManager(message);
    }
    queryParameters.delete("message");
  }, []);

  return (
    <>
      <Helmet>
        <html lang={methods.locale.replace("_", "-")} />
      </Helmet>

      <Routes>
        <Route
          path="*"
          element={
            <Navigate
              replace
              to={`/${preferredLanguage}/404${window.location.search}`}
            />
          }
        />

        <Route path="/loading" element={<LoadingScreen />} />

        <Route element={<DocumentLayout />}>
          {makeLocalizedRoute({
            path: "/404",
            element: <ErrorPage />,
            preferredLanguage,
          })}

          {makeLocalizedRoute({
            path: "/",
            element: <HomePage />,
            preferredLanguage,
          })}

          {makeLocalizedRoute({
            path: "/about",
            element: <AboutPage />,
            preferredLanguage,
          })}

          {makeLocalizedRoute({
            path: "/mensa",
            element: <SpAIseplanPage />,
            preferredLanguage,
          })}

          {makeLocalizedRoute({
            path: "/legacyCampus",
            element: <LegacyCampusPage />,
            preferredLanguage,
          })}

          <Route
            element={
              <RequireAuth adminRequired>
                <Outlet />
              </RequireAuth>
            }
          >
            {makeLocalizedRoute({
              path: "/admin",
              element: <AdminPage />,
              preferredLanguage,
            })}

            {makeLocalizedRoute({
              path: "/admin/moderation",
              element: <ModerationPage />,
              preferredLanguage,
            })}
          </Route>

          {makeLocalizedRoute({
            path: "/monitor/research-group/preview",
            element: <ResearchGroupSlidePreviewPage />,
            preferredLanguage,
          })}
        </Route>

        <Route element={<FullscreenLayout />}>
          {makeLocalizedRoute({
            path: "/exhibition",
            element: <ExhibitionPage />,
            errorElement: <ErrorPage />,
            preferredLanguage,
          })}

          {makeLocalizedRoute({
            path: "/waves-jl",
            element: <WavesJLPage />,
            preferredLanguage,
          })}

          {makeLocalizedRoute({
            path: "/monitor",
            element: <MonitorPage />,
            preferredLanguage,
          })}

          {makeLocalizedRoute({
            path: "/monitor/mensa",
            element: <MonitorMensaPage />,
            preferredLanguage,
          })}
        </Route>
      </Routes>

      <ToastManagerComponent doNotAddDefault={true} />
      <OffcanvasComponent />
    </>
  );
};
export default App;
