import * as React from "react";

import Apollo from "#/resources/images/apollo.png";
import { Caroussel, CarousselElement } from "./Caroussel";
import { AppLogo } from "./NavigationBar";
import { T } from "./T";

export const LoadingBackgroundEffect = () => (
  <>
    <div className="rotate-loop absolute inset-0 flex items-center justify-center overflow-hidden">
      <div
        className="bg-jgu-red h-32 w-32 rounded-full blur-3xl"
        style={{
          transform: `translate(-4rem, 10rem)`,
        }}
      />
    </div>
    <div className="rotate-loop absolute inset-0 flex items-center justify-center">
      <div
        className="bg-jgu-green h-24 w-24 rounded-full blur-3xl"
        style={{
          transform: `translate(4rem, -3rem)`,
        }}
      />
    </div>
    <div className="rotate-loop-reverse absolute inset-0 flex items-center justify-center">
      <div
        className="bg-jgu-blue h-44 w-44 rounded-full blur-3xl"
        style={{
          transform: `translate(-4rem, 8rem)`,
        }}
      />
    </div>
    <div className="rotate-loop-reverse absolute inset-0 flex items-center justify-center">
      <div
        className="bg-jgu-purple h-44 w-44 rounded-full blur-3xl"
        style={{
          transform: `translate(-2rem, -4rem)`,
        }}
      />
    </div>
    <div className="rotate-loop-reverse absolute inset-0 flex items-center justify-center">
      <div
        className="bg-jgu-yellow h-12 w-12 rounded-full blur-3xl"
        style={{
          transform: `translate(-8rem, 8rem)`,
        }}
      />
    </div>
  </>
);

const LoadingScreenTip = (props: {
  title: React.ReactNode;
  description: React.ReactNode;
  icon?: React.ReactNode;
}) => {
  const container = React.useRef<HTMLDivElement>(null);
  const fontSize = React.useRef<number>(100);

  React.useEffect(() => {
    const resizeListener = () => {
      if (container.current) {
        fontSize.current =
          Math.min(
            container.current.clientWidth,
            container.current.clientHeight,
          ) * 0.8;
      }
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  return (
    <div
      className="relative flex h-full w-full flex-col justify-center gap-4 p-8"
      ref={container}
    >
      {props.icon ? (
        <div
          className="absolute inset-0 flex items-center justify-center opacity-10"
          style={{
            fontSize: fontSize.current + "px",
          }}
        >
          {props.icon}
        </div>
      ) : null}
      <div className="flex w-full justify-between text-center text-3xl md:text-6xl">
        <p>{props.title}</p>
      </div>
      <p className="md:text-2xl">{props.description}</p>
    </div>
  );
};

export const LoadingScreen = (props: {
  zIndex: number;
  open: boolean;
  setOpen: (_: boolean) => void;
  showMessage?: boolean;
  customMessage?: React.ReactNode;
  className?: string;
  showTips?: boolean;
  style?: React.CSSProperties;
}) => {
  const [animationState, setAnimationState] = React.useState<string>(
    props.open
      ? (props.showTips ? "opacity-100" : "opacity-80") + " pointer-events-auto"
      : "pointer-events-none",
  );

  React.useEffect(() => {
    if (props.open) {
      setAnimationState(
        (props.showTips ? "opacity-100" : "opacity-80") +
          " pointer-events-auto",
      );
    } else {
      setAnimationState("opacity-0 pointer-events-none");
    }
  }, [props.open]);

  return (
    <div
      className={[
        props.className,
        "bg-white absolute flex h-full w-full cursor-wait justify-center overflow-hidden bg-linear-to-tr transition-[opacity] duration-500 ease-in-out",
        animationState,
      ].join(" ")}
      style={{ ...props.style, zIndex: props.zIndex }}
    >

      <LoadingBackgroundEffect />

      <div className={"flex h-full w-full flex-col items-center gap-3"}>
        {props.showTips ? (
          <div className="flex h-full w-full flex-col items-center">
            <div className="text-jgu-black flex h-20 w-full justify-center text-xl">
              <a className="container flex h-full items-center justify-between">
                <AppLogo />

                <div className="animate-spin">
                  <i className="bi bi-hourglass text-jgu-gray p-4"></i>
                </div>
              </a>
            </div>

            <div className="container flex grow items-center justify-center">
              <Caroussel className="">
                <CarousselElement>
                  <LoadingScreenTip
                    title={<T de="Pixelige Poster?" en="Pixelated posters?" />}
                    description={
                      <T
                        de="Versuche einen höheren Performance Mode auf der Startseite oder in den Details der Ausstellung zu setzen."
                        en="Try setting a higher performance mode on the overview page or inside the exhibition's details."
                      />
                    }
                    icon={<i className="bi bi-image"></i>}
                  />
                </CarousselElement>
                <CarousselElement>
                  <LoadingScreenTip
                    title={<T de="VR?" en="VR?" />}
                    description={
                      <T
                        de="Du kannst alle Ausstellungen auf VR Headsets besuchen, die WebXR unterstützen. Suche nach diesem Symbol zum starten. Für die beste Erfahrung in VR empfehlen wir dir ein kabelgebundenes Headset (oder Oculus Link) zu verwenden."
                        en="You can visit all exhibitions on VR headsets that support WebXR. Look for this symbol to start. For best experience in VR, we recommend you to use a wired headset (or Oculus Link)."
                      />
                    }
                    icon={<i className="bi bi-headset-vr"></i>}
                  />
                </CarousselElement>
                <CarousselElement>
                  <LoadingScreenTip
                    title={<T de="Bewegung?" en="Movement?" />}
                    description={
                      <T
                        de="Du kannst dich bewegen indem du auf das weiße Kreis-Gizmo auf dem Boden klickst."
                        en="You can move by clicking the white circular gizmo on the ground."
                      />
                    }
                    icon={<i className="bi bi-arrow-up"></i>}
                  />
                </CarousselElement>
                <CarousselElement>
                  <LoadingScreenTip
                    title={<T de="Interaktivität?" en="Interactivity?" />}
                    description={
                      <T
                        de="Einige Objekte können bewegt oder mit ihnen interagiert werden. Du kannst sie an ihrer leuchtenden Umrandung erkennen."
                        en="Some objects can be moved around or interacted with. You can identify them by their glowing outline."
                      />
                    }
                    icon={<i className="bi bi-box"></i>}
                  />
                </CarousselElement>
                <CarousselElement>
                  <LoadingScreenTip
                    title={<T de="Noob oder Pro?" en="Noob or Pro?" />}
                    description={
                      <T
                        de="Wenn du den PRO Mode aktivierst, kannst du dich mit den WASD Tasten auf deiner Tastatur bewegen."
                        en="By enabling the PRO Mode you can move around using the WASD keys on your keyboard."
                      />
                    }
                    icon={<i className="bi bi-keyboard-fill"></i>}
                  />
                </CarousselElement>
                <CarousselElement>
                  <LoadingScreenTip
                    title={
                      <T de="Motion Controller?" en="Motion Controller?" />
                    }
                    description={
                      <T
                        de="Benutze den Joystick auf deinem Motion Controller um dich in der Ausstellung zu bewegen. Der Trigger kann verwendet werden um mit Objekten zu interagieren."
                        en="Use the joystick on your motion controller to move around the exhibition. The trigger can be used to interact with objects."
                      />
                    }
                    icon={<i className="bi bi-joystick"></i>}
                  />
                </CarousselElement>
                <CarousselElement>
                  <LoadingScreenTip
                    title={<T de="Guide gefällig?" en="Need a guide?" />}
                    description={
                      <T
                        de="Schau nach Apollo in der Ausstellung. Er wird dich durch diese führen."
                        en="Look for Apollo in the exhibition. It will guide you through it."
                      />
                    }
                    icon={
                      <img
                        src={Apollo}
                        className="h-4/5 w-4/5 object-contain"
                      ></img>
                    }
                  />
                </CarousselElement>
              </Caroussel>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoadingScreen;
