import { ReactNode } from "react";
import { useLitteraMethods } from "@assembless/react-littera";
import Cookies from "js-cookie";

export const T = (props: {
  de: ReactNode;
  en: ReactNode;
  grayOut?: boolean;
  padding?: boolean;
  className?: string;
}) => {
  const methods = useLitteraMethods();
  const opacity = props.grayOut ? 0.25 : 1;
  const classes = props.padding ? "mt-2 mb-2" : "" + " " + props.className;

  return (
    <span className={classes} style={{ opacity }}>
      {methods.locale == "de_DE" ? props.de : props.en}
    </span>
  );
};

export const usePreferredLanguage = () => {
  return (Cookies.get("language") ??
    (navigator.language.match(/de/) ? "de" : "en")) as "de" | "en";
};
