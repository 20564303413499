import * as React from "react";
import { Button } from "./Button";
import { ModernTheme } from "./Theme";

interface CarousselProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const CarousselElement = (props: CarousselProps) => {
  return props.children;
};

export const Caroussel = (props: {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactElement<CarousselProps>[];
  autoPlay?: boolean;
}) => {
  const [tipIndex, setTipIndex] = React.useState<number>(
    Math.floor(Math.random() * (props.children.length - 1)),
  );

  React.useEffect(() => {
    if (!props.autoPlay) return;
    const interval = setInterval(() => {
      setTipIndex(
        (tipIndex + props.children.length - 1) % props.children.length,
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [tipIndex]);

  return (
    <div
      className={
        "container flex h-full w-full flex-col items-center justify-center md:flex-row"
      }
      style={props.style}
    >
      <div className="flex items-center justify-center">
        <Button
          theme={ModernTheme.Orange}
          className="pointer-events-auto z-50 rounded-full font-bold text-white"
          onClick={() => {
            setTipIndex((tipIndex + 1) % props.children.length);
          }}
        >
          <div className="relative rotate-90 md:rotate-0">
            <i className="bi bi-caret-left-fill absolute inset-0 animate-ping"></i>
            <i className="bi bi-caret-left-fill"></i>
          </div>
        </Button>
      </div>

      <div
        className={"relative w-full overflow-hidden " + props.className}
        style={props.style}
      >
        <div
          className="flex h-full flex-row transition-all duration-500 ease-in-out"
          style={{
            transform:
              "translateX(-" +
              ((props.children.length - 1 - tipIndex) / props.children.length) *
                100 +
              "%)",
            width: props.children.length * 100 + "%",
          }}
        >
          {props.children.map((child, index) => {
            return (
              <div
                key={index}
                className="h-full"
                style={{
                  width: 100 / props.children.length + "%",
                }}
              >
                {child}
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center">
        <Button
          theme={ModernTheme.Orange}
          className="text-jgu-white pointer-events-auto z-50 rounded-full"
          onClick={() => {
            setTipIndex(
              (tipIndex + props.children.length - 1) % props.children.length,
            );
          }}
        >
          <div className="relative rotate-90 md:rotate-0">
            <i className="bi bi-caret-right-fill absolute inset-0 animate-ping"></i>
            <i className="bi bi-caret-right-fill"></i>
          </div>
        </Button>
      </div>
    </div>
  );
};
