import { useLitteraMethods } from "@assembless/react-littera";
import Cookies from "js-cookie";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "#/resources/images/logos/logo.svg";
import { Button } from "./Button";
import { T, usePreferredLanguage } from "./T";
import { useAuthContext } from "@/utility/Authentication";

import LanguageDE from "#/resources/images/icons/language_de.svg";
import LanguageEN from "#/resources/images/icons/language_en.svg";
import { ModernTheme } from "./Theme";

interface LanguageToggleProps {
  className?: string;
}

export const LanguageToggle = (_props: LanguageToggleProps) => {
  const methods = useLitteraMethods();
  const lang = methods.locale;

  let f: (() => void) | null = null;
  if (lang == "de_DE") {
    f = () => {
      methods.setLocale("en_US");
      Cookies.set("language", "en");
      window.location.replace(
        window.location.href.replace(
          window.location.pathname,
          window.location.pathname.replace("/de", "/en"),
        ),
      );
    };
  } else {
    f = () => {
      methods.setLocale("de_DE");
      Cookies.set("language", "de");
      window.location.replace(
        window.location.href.replace(
          window.location.pathname,
          window.location.pathname.replace("/en", "/de"),
        ),
      );
    };
  }

  return (
    <NavLink
      className={"flex h-8 w-8 items-center justify-center uppercase"}
      to={f}
    >
      <T
        de={<img alt="" src={LanguageEN} className="h-5 w-5" />}
        en={<img alt="" src={LanguageDE} className="h-5 w-5" />}
      />
    </NavLink>
  );
};

const NavLink = (props: {
  children?: React.ReactNode;
  to: string | (() => void);
  className?: string;
  replace?: boolean;
  capture?: () => void;
}) => {
  const location = useLocation();
  const adjustedTo = props.to == "/" ? "" : props.to;
  return (
    <Link
      to={typeof props.to == "string" ? props.to : "#"}
      onClick={typeof props.to == "string" ? undefined : props.to}
      className={[
        "hover:text-jgu-red transition-all duration-200 ease-in-out hover:scale-110 hover:font-bold",
        props.className,
        typeof props.to == "string"
          ? [props.to, "/de" + adjustedTo, "/en" + adjustedTo].includes(
              location.pathname,
            )
            ? "text-jgu-red"
            : "text-jgu-gray"
          : "text-jgu-gray",
      ].join(" ")}
      replace={props.replace}
      onClickCapture={() => {
        props.capture?.call(props.capture);
      }}
    >
      {props.children}
    </Link>
  );
};

export const UserInfo = () => {
  const auth = useAuthContext();

  return (
    <a
      href={auth.data.success ? "/api/logout" : "/api/login"}
      className="text-jgu-gray-20 relative mr-1 overflow-hidden rounded-full pr-5 transition-all duration-200 ease-in-out hover:scale-110 hover:font-bold hover:text-white"
      onClick={(_) => {
        Cookies.set("REDIRECT", window.location.href);
      }}
    >
      <div className="bg-jgu-gray flex h-10 items-center gap-2 pr-6 pl-3">
        <div className="flex flex-col items-end text-xs">
          {auth.data.user ? (
            <>
              <div>{auth.data.user.name}</div>
              <T de="Abmelden" en="Logout" />
            </>
          ) : (
            <>
              <T de="Anmelden" en="Login" />
            </>
          )}
        </div>
        <div className="absolute top-0 right-0 bottom-0">
          <div className="relative flex h-10 w-10 items-center justify-center rounded-l-full">
            {auth.data.user && auth.data.user.picture ? (
              <img
                src={auth.data.user.picture}
                alt="Profile Picture"
                className="absolute inset-0 rounded-full"
              />
            ) : (
              <div className="bg-jgu-gray-20 absolute inset-0 flex items-center justify-center rounded-full text-xl">
                <i className="bi bi-person-fill text-jgu-gray" />
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export const AppLogo = (props: { noText?: boolean; mono?: boolean }) => {
  return (
    <div
      className="group m-1 mr-3 ml-3 flex gap-3 uppercase"
      style={{
        height: "2.75rem",
      }}
    >
      <object
        data={Logo}
        type="image/svg+xml"
        height="2.75rem"
        width="auto"
        className={
          "pointer-events-none h-11 transition-all duration-200 ease-in-out " +
          (props.mono
            ? "brightness-200 grayscale group-hover:brightness-100 group-hover:grayscale-0"
            : "")
        }
        // alt="Virtual Experiments Space"
      />
      {!props.noText && (
        <div className="flex items-center justify-center text-3xl">
          <div
            className={
              "flex flex-nowrap font-bold text-nowrap transition-all duration-200 ease-in-out " +
              (props.mono
                ? "group-hover:text-jgu-gray text-white"
                : "text-jgu-gray")
            }
          >
            V
          </div>
          <div
            className={
              "flex-no-wrap flex font-bold text-nowrap transition-all duration-200 ease-in-out " +
              (props.mono
                ? "group-hover:text-jgu-red text-white"
                : "text-jgu-red")
            }
          >
            E
          </div>
          <div
            className={
              "flex-no-wrap flex text-nowrap transition-all duration-200 ease-in-out " +
              (props.mono
                ? "group-hover:text-jgu-red text-white"
                : "text-jgu-red")
            }
          >
            S
          </div>
        </div>
      )}
    </div>
  );
};

export const NavigationBar = () => {
  const [height, setHeight] = React.useState("5rem");

  const preferredLanguage = usePreferredLanguage();

  const navClicked = () => {
    setHeight("5rem");
  };

  const navLinks = (
    <>
      <NavLink to={`/${preferredLanguage}`} capture={navClicked}>
        <T de="Ausstellungen & Experimente" en="Exhibitions & Experiments" />
      </NavLink>
      {/* <NavLink to={`/${preferredLanguage}/software`} capture={navClicked}>
        <T de="Software" en="Software" />
      </NavLink> */}
      {/* <NavLink to={`/${preferredLanguage}/mensa`} capture={navClicked}>
        <T de="SpAIseplan" en="SpAIseplan" />
      </NavLink> */}
      {/* <NavLink to={`/${preferredLanguage}/admin`} capture={navClicked}>
        <T de="Austellungsverwaltung" en="Exhibition Management" />
      </NavLink> */}
      <NavLink to={`/${preferredLanguage}/about`} capture={navClicked}>
        <T de="Hinter den Kulissen" en="Behind the Scenes" />
      </NavLink>
    </>
  );

  /* bg-linear-to-tr via-jgu-orange-20/95 from-jgu-red-20/95 to-jgu-purple-20/95 */
  return (
    <>
      <nav
        className="border-accent bg-white/70 fixed top-0 right-0 left-0 z-50 flex w-full flex-col items-center justify-start overflow-hidden border-solid bg-linear-to-tr shadow-xl backdrop-blur-2xl transition-[height] duration-300 ease-in-out"
        style={{
          height: height,
        }}
      >
        <div className="container flex h-20 max-h-20 min-h-20 flex-row justify-between">
          <div className="flex flex-wrap items-center justify-center">
            <Link
              to={`/${preferredLanguage}`}
              className="no-underline transition-all duration-200 ease-in-out hover:scale-110"
              onClickCapture={navClicked}
            >
              <AppLogo />
            </Link>
          </div>

          <div className="hidden h-full grow items-center justify-end gap-5 pr-4 pl-3 lg:flex">
            {navLinks}
            <div className="bg-jgu-white flex items-center gap-2 rounded-full p-1 pr-1 pl-3">
              <LanguageToggle />
              <UserInfo />
            </div>
          </div>

          <div className="flex h-full grow items-center justify-end gap-5 pl-3 lg:hidden">
            <Button
              theme={ModernTheme.Transparent}
              onClick={() => {
                setHeight(height == "5rem" ? "100vh" : "5rem");
              }}
            >
              <i className="bi bi-three-dots-vertical"></i>
            </Button>
          </div>
        </div>
        <div className="flex grow flex-col items-center gap-4 p-2 lg:hidden">
          <div className="flex grow flex-col items-center justify-center gap-4">
            {navLinks}
          </div>
          <div className="bg-jgu-white flex items-center gap-2 rounded-full p-1 pl-3">
            <LanguageToggle />
            <UserInfo />
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavigationBar;
